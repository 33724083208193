import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from "gatsby"
import './all.sass';
require('prismjs/themes/prism-okaidia.css');

const state = {
  menuToggled: false
};

const toggleMenu = () => {
  state.menuToggled = !state.menuToggled
}

class Header extends React.Component {

  toggleMenu = () => this.props.toggleMenu();

  render() {
    return (
      <nav className="navbar is-basement">
        <div className="navbar-brand">
          <div className="navbar-item">
            <Link
              to="/"
              className="title is-4"
              style={{
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center'
              }}
            >{this.props.data.site.siteMetadata.title}
            </Link>
          </div>
          <div className={`navbar-burger ${this.props.isActive ? 'is-active' : ''}`} onClick={this.toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className={`navbar-menu ${this.props.isActive ? 'is-active' : ''}`}>
          <div className="navbar-end">
            <Link className="navbar-item" to="/" onClick={this.toggleMenu}>Home</Link>
            <Link className="navbar-item" to="/blog" onClick={this.toggleMenu}>Blog</Link>
          </div>
        </div>
      </nav>
    );
  }
}

class TemplateWrapper extends React.Component {

  state = {
    isActive: false
  }

  handleSetState = () => this.setState({ isActive: !this.state.isActive });

  render() {
    const { children } = this.props;

    return (
      <StaticQuery
        query={graphql`
          query LayoutQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <div>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                { name: 'description', content: 'Sample' },
                { name: 'keywords', content: 'sample, something' }
              ]}
            />
            <Header data={data} isActive={this.state.isActive} toggleMenu={this.handleSetState} />
            <div>
              {children}</div>
            <footer className="hero is-black is-bold is-footer">
              <div className="hero-body">© {data.site.siteMetadata.title}</div>
            </footer>
          </div>
        )}
      />
    );
  }
}

TemplateWrapper.propTypes = {
  children: PropTypes.func
};

export default TemplateWrapper;
